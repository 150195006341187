import React from 'react'
import "../styles/footer.scss"
import { Link } from 'gatsby'

// const dateToday = new Date()

// let thisYear = dateToday.getFullYear()



const Footer = () => ( 

  <footer className = "site-footer" >
  <ul>
    {/* <li><Link to="#">About</Link></li> */}
    <li><Link to="#">Terms of use</Link></li>
    <li><Link to="#">Advertise</Link></li>
    <li><Link to="#">Contact us</Link></li>
    {/* <li><Link to="#">Facebook</Link></li> */}
  </ul>
  </footer>
)

export default Footer