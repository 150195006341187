import React from "react"
import { Link } from "gatsby"

function Navigation({ menu }) {
  return (
    <nav className="nav">
      <ul className="nav-list">
        {menu.map(menuItem => {
          return (
            <li className="nav-list--item" key={menuItem.id}>
              <Link to={menuItem.path} activeClassName="active">{menuItem.label}</Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default Navigation
