import React, {useState} from "react"
import Logo from "./logo"
import { graphql, useStaticQuery } from "gatsby"
import Navigation from "./navigation"

const Header = () => {

  const data = useStaticQuery(graphql`
    query {
      wpMenu(name: { eq: "Main Menu" }) {
        id
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
    }
  `)

  const [toggler, setToggler] = useState(false)

  const handleNavicon = () => {
      setToggler(!toggler)
  }

  const menuStatus = toggler ? "_open" : "_closed"

  return (
    <header className={`main-header ${menuStatus}`}>
      <Logo />
      <Navigation menu={data.wpMenu.menuItems.nodes}  />
      <span aria-hidden="true" className="navicon"><span onClick={handleNavicon}>{toggler ? "Close" : "Menu"}</span></span>
    </header>
  )
}

export default Header
