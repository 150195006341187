import React from "react"
// import Helmet from 'react-helmet'
import Header from "./partials/header"
import Footer from "./partials/footer"
// import { globalHistory } from "@reach/router"

// import { graphql } from "gatsby"

import "./index.scss"

const TemplateWrapper = ({ children, location, props }) => {
  // const isHomepage = globalHistory.location.pathname === withPrefix("/")

  return (
    <div className="wrap">
      {/* <h1>Welcome {isHomepage ? "home" : "aboard"}!</h1> */}
      <Header />
      <div className="wrap-content">{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
