import React from "react"
import {Helmet} from "react-helmet"
import {StaticQuery, graphql} from "gatsby"

export const Seo =({description, keywords, title, image, url, author, schemaMarkup}) => {
    
    return (
        <StaticQuery 
            query = {detailsQuery}
            render = {data => {
                const metaDescription = description || data.site.siteMetadata.description
                const metaTitle = title || data.site.siteMetadata.title
                const metaUrl = url || data.site.siteMetadata.url
                const metaImage = image || data.site.siteMetadata.siteUrl + data.site.siteMetadata.seoImage
                const metaKeywords = keywords || ['news', 'kasargod news', 'kanhangad news', 'kasaragod news', 'malayalam news']

                return (
                    <Helmet
                     title={title}
                     description={metaDescription}
                     meta={[
                        { itemprop: 'name', content: metaTitle },
                        { itemprop: 'description', content: metaDescription },
                        { itemprop: 'image', content: metaImage },
                        { name: 'description', content: metaDescription },
                        { property: 'og:title', content: metaTitle },
                        { property: 'og:image', content: metaImage },
                        { property: 'og:type', content: 'article' },
                        { property: 'og:description', content: metaDescription },
                        { property: 'og:site_name', content: data.site.siteMetadata.title },
                        { property: 'fb:pages', content: '121841357892943' },
                        { property: 'og:url', content: metaUrl },
                        { name: 'twitter:card', content: 'summary_large_image' },
                        { name: 'twitter:title', content: metaTitle },
                        { name: 'twitter:creator', content: '@_latestonline' },
                        { name: 'twitter:site', content: '@_latestonline' },
                        { name: 'twitter:description', content: metaDescription },
                        { name: 'twitter:image', content: metaImage },
                      ].concat(
                          metaKeywords && metaKeywords.length > 0 ? {
                              name: `keywords`,
                              content: metaKeywords.join(`, `),
                          } : []
                      )
                    }
                     >
                         {schemaMarkup &&
                         <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
                         }
                        <html lang="en"/>
                     </Helmet>
                )

            } }
        />
    )
}

const detailsQuery = graphql`
    query DefaultSeoQuery {
            site {
                siteMetadata {
                title
                description
                author
                siteUrl
                seoImage
                }
            }
            }

`

export default Seo