import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
export default function Logo() {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <h1 className="logo"><Link to="/">{data.site.siteMetadata.title}</Link></h1>
        </>
      )}
    />
  )
}